import React from "react";
import { useState, useEffect } from "react";
import { Row, Col, Card, Table,  Badge } from 'react-bootstrap';
import "react-datetime/css/react-datetime.css";
import { prodmonitoringmetrics ,jobs, getSAPdbupdateddata, getMDMdbupdateddata} from "../service/getdata";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

function InterfaceHealth() {
    const [prodmonitormetricsdata, setprodmonitormetricsdata] = useState([]);
    const [jobsdata, setjobsdata] = useState([]);
    const [SAPtruckmetricsdata, setSAPtruckmetricsdata] = useState([]);
    const [SAPdevicemetricsdata, setSAPdevicemetricsdata] = useState([]);
    const [SAPdbupdateddata, setSAPdbupdateddata] = useState([]);
    const [MDMdbupdateddata, setMDMdbupdateddata] = useState([]);
    const findlatesttimestampjob = (datum) => {
      let dates = jobsdata.filter((a)=>a.rowKey.indexOf(datum)>=0).map((b)=>new Date(b.timestamp))
      return new Date(Math.max.apply(null, dates)).toLocaleString();    
    }
  
    useEffect(() => {
      jobs().then((d) => {
        console.log(d)
        setjobsdata(JSON.parse(JSON.stringify(d?.data)))
  
      });
    }, [jobsdata.length])
  
    useEffect(() => {
        prodmonitoringmetrics().then((d) => {
        //console.log(db)
        setprodmonitormetricsdata(JSON.parse(JSON.stringify(d?.data)))
        //console.log(prodmonitormetricsdata)
      });
    }, [prodmonitormetricsdata.length,SAPdbupdateddata.length])
    
    useEffect(() => {
        var datum = []
        //initialize placeholder values
        datum["IncomingRecords"] = 10;
        datum["RoutedRecords"] = 0;
        datum["SavedRecords"] = 0;
        //set real values based on data in metrics result set
        prodmonitormetricsdata.map((item, id) => {
            if(item.partitionKey==="EVENTGRID" && item.rowKey==="gts-app-eus-per-eg-saptruck"){
                datum["IncomingRecords"] = item.PublishedEvents;
            } else if(item.partitionKey==="EVENTHUB" && item.rowKey==="truckinbound"){
                datum["RoutedRecords"] = item.IncomingMessages_truckinbound;
            }
        })    

        SAPdbupdateddata.map((item, id) => {
            if (item.VehicleCount !== undefined){
                datum["SavedRecords"] = item.VehicleCount
            }            
        })  
        setSAPtruckmetricsdata(datum)
        //console.log(SAPtruckmetricsdata)
    }, [SAPtruckmetricsdata.length,SAPdbupdateddata.length,prodmonitormetricsdata.length])

    useEffect(() => {
        getSAPdbupdateddata().then((d) => {
        //console.log(d)
        setSAPdbupdateddata(JSON.parse(JSON.stringify(d?.data)))
        //console.log(setSAPdbupdateddata)
      });
    }, [SAPdbupdateddata.length,prodmonitormetricsdata.length])

    useEffect(() => {
        var datum = []
        //initialize placeholder values
        datum["IncomingRecords"] = 10;
        datum["RoutedRecords"] = 0;
        datum["SavedRecords"] = 0;
        //set real values based on data in metrics result set
        prodmonitormetricsdata.map((item) => {
            if(item.partitionKey==="EVENTGRID" && item.rowKey==="gts-app-eus-per-eg-sapdevice"){
                datum["IncomingRecords"] = item.PublishedEvents;
            } else if(item.partitionKey==="EVENTHUB" && item.rowKey==="deviceinbound"){
                datum["RoutedRecords"] = item.IncomingMessages_deviceinbound;
            }
        })    

        SAPdbupdateddata.map((item) => {
            if (item.DeviceCount !== undefined){
                datum["SavedRecords"] = item.DeviceCount
            }            
        })  

        setSAPdevicemetricsdata(datum)
        //console.log(SAPdevicemetricsdata)
    }, [SAPdevicemetricsdata.length,SAPdbupdateddata.length,prodmonitormetricsdata.length])

    useEffect(() => {
        var datum = []
        //initialize placeholder values
        datum["IncomingRecords"] = 10;        
        datum["SavedRecords"] = 0;
        //set real values based on data in metrics result set
        prodmonitormetricsdata.map((item, id) => {
            if(item.partitionKey==="EVENTHUB" && item.rowKey==="customermdm"){
                datum["IncomingRecords"] = item.IncomingMessages_customermdm;
            }
        })    

        MDMdbupdateddata.map((item, id) => {
            if (item.CustomerCount !== undefined){
                datum["SavedRecords"] = item.CustomerCount
            }            
        })  
        setMDMdbupdateddata(datum)
        //console.log(MDMdbupdateddata)
    }, [MDMdbupdateddata.length,prodmonitormetricsdata.length])
    
    return (
        <>     
          <Row>
            <Col className="m-1">
              <Card style={{ height: 200 }}>
                <Card.Header >
                  <strong>SAP Inbound Interface</strong>
                    {                
                        ((new Date().getTime() - new Date(findlatesttimestampjob("eventgrid")).getTime()) > 300000)
                        ?
                        <Badge pill style={{ float: 'right' }} bg="warning">
                        {
                            findlatesttimestampjob("eventgrid")
                        }
                        </Badge>
                        :
                        <Badge pill style={{ float: 'right' }} bg="success">
                        {
                            findlatesttimestampjob("eventgrid")
                        }
                        </Badge>
                    }               
                </Card.Header>
                <Card.Body style={{ overflow: 'auto' }}>              
                  <Table className='table-sm table-striped od-font-sm'>
                    <thead>
                      <tr>
                        <th className='small text-center'>Entity</th>
                        <th className='small text-center'>Incoming Records (Event Grid)</th>
                        <th className='small text-center'>Routed Records (Event Hub)</th>
                        <th className='small text-center'>Saved Records (Database)</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='small text-center'>Truck</td>
                            <td className='small text-center'>{SAPtruckmetricsdata["IncomingRecords"]}</td>
                            <td className='small text-center'>{SAPtruckmetricsdata["RoutedRecords"]}</td>
                            <td className='small text-center'>{SAPtruckmetricsdata["SavedRecords"]}</td>
                        </tr>
                        <tr>
                            <td className='small text-center'>Device</td>
                            <td className='small text-center'>{SAPdevicemetricsdata["IncomingRecords"]}</td>
                            <td className='small text-center'>{SAPdevicemetricsdata["RoutedRecords"]}</td>
                            <td className='small text-center'>{SAPdevicemetricsdata["SavedRecords"]}</td>
                        </tr>
                    </tbody>
                  </Table>
                  
                </Card.Body>
              </Card>
            </Col>
            <Col className="m-1">
              <Card style={{ height: 200 }}>
                <Card.Header >
                  <strong>MDM Inbound Interface</strong>
                    {                
                        ((new Date().getTime() - new Date(findlatesttimestampjob("eventhubtoyota")).getTime()) > 300000)
                        ?
                        <Badge pill style={{ float: 'right' }} bg="warning">
                        {
                            findlatesttimestampjob("eventhubtoyota")
                        }
                        </Badge>
                        :
                        <Badge pill style={{ float: 'right' }} bg="success">
                        {
                            findlatesttimestampjob("eventhubtoyota")
                        }
                        </Badge>
                    }                  
                </Card.Header>
                <Card.Body style={{ overflow: 'auto' }}>              
                  <Table className='table-sm table-striped od-font-sm'>
                    <thead>
                      <tr>
                        <th className='small text-center'>Incoming Records (Event Grid)</th>
                        <th className='small text-center'>Saved Records (Database)</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr>                            
                            <td className='small text-center'>{MDMdbupdateddata["IncomingRecords"]}</td>
                            <td className='small text-center'>{MDMdbupdateddata["SavedRecords"]}</td>                            
                        </tr>
                    </tbody>
                  </Table>
                  
                </Card.Body>
              </Card>
            </Col>
    
          </Row>
          <Row>
            <Col className="m-1">
              <Card style={{ height: 200 }}>
                <Card.Header >
                  <strong>Factory Programming APIs</strong> 
                    {                
                        ((new Date().getTime() - new Date(findlatesttimestampjob("apimtoyota")).getTime()) > 300000)
                        ?
                        <Badge pill style={{ float: 'right' }} bg="warning">
                        {
                            findlatesttimestampjob("apimtoyota")
                        }
                        </Badge>
                        :
                        <Badge pill style={{ float: 'right' }} bg="success">
                        {
                            findlatesttimestampjob("apimtoyota")
                        }
                        </Badge>
                    }                  
                </Card.Header>
                <Card.Body style={{ overflow: 'auto' }}>              
                  <Table className='table-sm table-striped od-font-sm'>
                    <thead>
                      <tr>
                        <th className='small text-center'>API Functional Purpose</th>
                        <th className='small text-center'>Total Requests</th>
                        <th className='small text-center'>Successful Requests</th>
                        <th className='small text-center'>Failed Requests</th>
                        <th className='small text-center'>Blocked Requests</th>
                        <th className='small text-center'>Avg (time)</th>
                        <th className='small text-center'>Min (time)</th>
                        <th className='small text-center'>Max (time)</th>
                        <th className='small text-center'>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                        {
                            prodmonitormetricsdata.map((item, id) => {
                                return item.partitionKey==="APIM" ? 
                                    <tr key={id}>
                                        <td className='small text-center'>{item.rowKey}</td>
                                        <td className='small text-center'>{item.TotalRequests}</td>
                                        <td className='small text-center'>{item.SuccessfulRequests}</td>
                                        <td className='small text-center'>{item.FailedRequests}</td>
                                        <td className='small text-center'>{item.BlockedRequests}</td>
                                        <td className='small text-center'>{parseFloat(item.apiTimeAvg).toFixed(2)}</td>
                                        <td className='small text-center'>{parseFloat(item.apiTimeMin).toFixed(2)}</td>
                                        <td className='small text-center'>{parseFloat(item.apiTimeMax).toFixed(2)}</td>
                                        <td className='small text-center'><FontAwesomeIcon icon={faCircle} className={(item.TotalRequests-item.SuccessfulRequests) <= 10 ? 'text-success' : 'text-danger'} /></td>
                                    </tr>
                                    :<></>
                            }
                        )}                        
                    </tbody>
                  </Table>
                  
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      );
    }
    
    export default InterfaceHealth;
    