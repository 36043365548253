import React from "react";
import { useState, useEffect } from "react";
import { Row, Col, Card, Table, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import "react-datetime/css/react-datetime.css";
import { iotmetrics, servicebusmetrics, sajmetrics, eventhubmetrics, thresholds ,jobs} from "../service/getdata";


function DeviceCommunication() {
  const [iot, setiot] = useState([]);
  const [servicebus, setservicebus] = useState([]);
  const [saj, setsaj] = useState([]);
  const [eventhub, seteventhub] = useState([]);
  const [thresholddata, setthresholddata] = useState([]);
  //const [statusmsg, setstatusmsg] = useState("OK");
  let statusmsg;

  const [jobsdata, setjobsdata] = useState([]);
  const findlatesttimestampjob = (datum) => {
    let dates = jobsdata.filter((a)=>a.rowKey.indexOf(datum)>=0).map((b)=>new Date(b.timestamp))
    return new Date(Math.max.apply(null, dates)).toLocaleString();    
  }

  useEffect(() => {
    jobs().then((d) => {
      //console.log(db)
      setjobsdata(JSON.parse(JSON.stringify(d?.data)))

    });
  }, [jobsdata.length])

  const getstatus = (datum) => {
    //var statusmsg = "";
    var status = true;
    // //console.log(thresholddata)
    // thresholddata.map((d) => {
    //   //let js = JSON.parse(d)
    //   if (d["rowKey"] === datum["rowKey"]) {        
    //     if (d["DeviceCount"]["max"] >= datum["DeviceCount"]) {
    //       status=true;
    //     }
    //   }
    // })
    return <FontAwesomeIcon icon={faCircle} className={status ? 'text-success' : 'text-danger'} />
  }

  useEffect(() => {
    iotmetrics().then((d) => {
      //console.log(iot)
      setiot(d?.data)
    });
  }, [iot.length,statusmsg])

  useEffect(() => {
    servicebusmetrics().then((d) => {
      setservicebus(d?.data)
    });
  }, [servicebus.length])

  useEffect(() => {
    sajmetrics().then((d) => {
      setsaj(d?.data)
    });
  }, [saj.length])

  useEffect(() => {
    eventhubmetrics().then((d) => {
      seteventhub(d?.data)
      console.log(eventhub)
    });
  }, [eventhub.length])

  useEffect(() => {
    thresholds().then((d) => {
      //console.log(d)
      
      let js = [];      
      d.data.map((item)=>{
        var m = Object.keys(item);
        let temp={};
        for(var i=0;i<m.length;i++)
        {          
          if(m[i] === "rowKey")
          {
            temp[m[i]]=item[m[i]]
          }
          if(m[i] !== "partitionKey" && m[i] !== "rowKey" && m[i] !== "timestamp" && m[i] !== "etag")
          {
            temp[m[i]]=JSON.parse(item[m[i]])
          }        
        }
        js.push(temp)
      })      
      setthresholddata(js)
    });
  }, [thresholddata.length])

  return (
    <>
      <Row>
        <Col className="m-1">
          <Card>
            <Card.Header >
              <strong>IOT Hub</strong>
              {                
                ((new Date().getTime() - new Date(findlatesttimestampjob("iot")).getTime()) > 300000)
                ?
                <Badge pill style={{ float: 'right' }} bg="warning">
                  {
                    findlatesttimestampjob("iot")
                  }
                </Badge>
                :
                <Badge pill style={{ float: 'right' }} bg="success">
                  {
                    findlatesttimestampjob("iot")
                  }
                </Badge>
              }
            </Card.Header>
            <Card.Body>
              <Table className='table-sm table-striped od-font-sm'>
                <thead>
                  <tr>
                    <th className='small'>IOT Hub</th>
                    <th className='small'>Avg Latency	&#40;Seconds&#41;</th>
                    <th className='small'>ConnectedDevices</th>
                    <th className='small d2c'>D2CMessagesReceived</th>
                    <th className='small d2c'>D2CMessagesDropped</th>
                    <th className='small c2d'>C2DMessagesExpired</th>
                    <th className='small c2d'>C2DMessagesSent</th>
                    <th className='small c2d'>C2DMessagesAbandoned</th>
                    <th className='small c2d'>C2DMessagesRejected</th>
                    <th className='small'>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    iot.map((item, id) => {
                      return <tr key={id}>
                        <td className='small'>{item.rowKey}</td>
                        <td className='small'>{parseFloat(item.AverageLatency).toFixed(2)}</td>
                        <td className='small'>{item.DeviceCount}</td>
                        <td className='small'>{item.D2CMessagesReceived}</td>
                        <td className='small'>{item.D2CMessagesDropped}</td>
                        <td className='small'>{item.C2DMessagesExpired}</td>
                        <td className='small'>{item.C2DMessagesSent}</td>
                        <td className='small'>{item.C2DMessagesAbandoned}</td>
                        <td className='small'>{item.C2DMessagesRejected}</td>
                        <td className='small'>{getstatus(item)}</td>
                      </tr>
                    })
                  }
                </tbody>
              </Table>
              <strong className="od-font-sm">{statusmsg}</strong>
            </Card.Body>
          </Card>
          
        </Col>
      </Row>
      <Row>
        <Col className="m-1">
          <Card style={{ height: 275 }}>
            <Card.Header >
              <strong>Event Hub</strong>
              {                
                ((new Date().getTime() - new Date(findlatesttimestampjob("eventhub")).getTime()) > 300000)
                ?
                <Badge pill style={{ float: 'right' }} bg="warning">
                  {
                    findlatesttimestampjob("eventhub")
                  }
                </Badge>
                :
                <Badge pill style={{ float: 'right' }} bg="success">
                  {
                    findlatesttimestampjob("eventhub")
                  }
                </Badge>
              }
            </Card.Header>
            <Card.Body style={{overflow:'auto'}}>
              <Table className='table-sm table-striped od-font-sm'>
                <thead>
                  <tr>
                    <th className='small od-font-sm'>Namespace</th>
                    <th className='small od-font-sm'>EventHub</th>
                    <th className='small od-font-sm'>Backlogged Events</th>
                    <th className='small od-font-sm'>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    eventhub.map((item, id) => {
                      return <tr key={id}>
                        <td className='small'>{item.rowKey}</td>
                        <td className='small'>{item.eventhub}</td>
                        <td className='small'>{item.backlog}</td>
                        <td className='small'><FontAwesomeIcon icon={faCircle} className={item.backlog <= 200 ? 'text-success' : 'text-danger'} /></td>
                      </tr>
                    })
                  }
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>

        <Col className="m-1">
          <Card style={{ height: 275 }} >
            <Card.Header >
              <strong>Service Bus</strong>
              {                
                ((new Date().getTime() - new Date(findlatesttimestampjob("servicebus")).getTime()) > 300000)
                ?
                <Badge pill style={{ float: 'right' }} bg="warning">
                  {
                    findlatesttimestampjob("servicebus")
                  }
                </Badge>
                :
                <Badge pill style={{ float: 'right' }} bg="success">
                  {
                    findlatesttimestampjob("servicebus")
                  }
                </Badge>
              }
            </Card.Header>
            <Card.Body style={{ overflow: 'auto' }}>
              <Table className='table-sm table-striped od-font-sm'>
                <thead>
                  <tr>
                    <th className='small'>Service Bus</th>
                    {/* <th className='small'>Queue</th> */}
                    <th className='small'>Active</th>
                    <th className='small'>Deadletter</th>
                    <th className='small'>Free %</th>
                    <th className='small'>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    servicebus.map((item, id) => {
                      return <tr key={id}>
                        <td className='small'>{item.rowKey}</td>
                        {/* <td className='small'>{item.queue}</td> */}
                        <td className='small'>{item.Active}</td>
                        <td className='small'>{item.Deadletter}</td>
                        <td className='small'>{item.free}</td>
                        <td className='small'><FontAwesomeIcon icon={faCircle} className={item.Active <= 100 ? 'text-success' : 'text-danger'} /></td>
                      </tr>
                    })
                  }
                </tbody>
              </Table>
              {/* <ReactDataGrid
                  //idProperty="_id"
                  //className="mb-4"
                  style={gridStyle}
                  rowStyle={rowStyle}
                  columns={gridcols}
                  //selected={props.selected}
                  dataSource={alertsdata}
                  //onRowClick={handleGridRowClick}
                  defaultFilterValue={filterValue}
                  enableColumnAutosize
                  pagination="local"
                  //checkboxColumn={props.checkboxreq}
                  //multiSelect={props.multiSelect}
                  //editable={props.editable}
                  //onSelectionChange={(e) => onSelectionChange(e)}
                  defaultLimit={25}
                  //defaultSkip={0}
                  pageSizes={[25, 50, 100, 500]}
                //maxWidth ={600}
                /> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className="m-1">
          <Card style={{ height: 275 }}>
            <Card.Header >
              <strong>Stream Analytics Jobs</strong>
              {                
                ((new Date().getTime() - new Date(findlatesttimestampjob("stream")).getTime()) > 300000)
                ?
                <Badge pill style={{ float: 'right' }} bg="warning">
                  {
                    findlatesttimestampjob("stream")
                  }
                </Badge>
                :
                <Badge pill style={{ float: 'right' }} bg="success">
                  {
                    findlatesttimestampjob("stream")
                  }
                </Badge>
              }
            </Card.Header>
            <Card.Body style={{ overflow: 'auto' }}>
              <Table className='table-sm table-striped od-font-sm'>
                <thead>
                  <tr>
                    <th className='small'>SAJ</th>
                    {/* <th className='small'>TimeStamp</th> */}
                    <th className='small'>Average Delay(Seconds)</th>
                    <th className='small'>Maximum Delay(Seconds)</th>
                    <th className='small'>Backlogged Events</th>
                    
                    <th className='small'>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    saj.map((item, id) => {
                      return <tr key={id}>
                        <td className='small'>{item.rowKey}</td>
                        {/* <td className='small'>{new Date(item.timestamp).toLocaleString()}</td> */}
                        <td className='small'>{parseInt(item.avg)}</td>
                        <td className='small'>{parseInt(item.max)}</td>
                        <td className='small'>{parseInt(item.backloggedevents)}</td>                        
                        <td className='small'><FontAwesomeIcon icon={faCircle} className={item.max <= 180 ? 'text-success' : 'text-danger'} /></td>
                      </tr>
                    })
                  }
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default DeviceCommunication;
