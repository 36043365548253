import axios from 'axios';

export async function dbmetrics() {
  const resp = await axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/dbmetrics",
  });
  return resp;
}

export async function iotmetrics() {
  const resp = await axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/iotmetrics",
  });
  return resp;
}

export async function portalmetrics() {
  const resp = await axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/portalmetrics",
  });
  return resp;
}


export async function releasemetrics() {
  const resp = await axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/releasemetrics",
  });
  return resp;
}

export async function servicebusmetrics() {
  const resp = await axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/servicebusmetrics",
  });
  return resp;
}

export async function sajmetrics() {
  const resp = await axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/sajmetrics",
  });
  return resp;
}

export async function apimetrics() {
  const resp = await axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/apimetrics",
  });
  return resp;
}


export async function eventhubmetrics() {
  const resp = await axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/eventhubmetrics",
  });
  return resp;
}

export async function alerts() {
  const resp = await axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/alerts",
  });
  return resp;
}


export async function cosmosdbmetrics() {
  const resp = await axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/cosmosdbmetrics",
  });
  return resp;
}

export async function thresholds() {
  const resp = await axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/thresholds",
  });
  return resp;
}

export async function jobs() {
  const resp = await axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/jobs",
  });
  return resp;
}

export async function dependencies() {
  const resp = await axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/dependencies",
  });
  return resp;
}

export async function prodmonitoringmetrics() {
  const resp = await axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/prodmonitoringmetrics",
  });
  return resp;
}

export async function distributionmetrics() {
  const resp = await axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/distributionmetrics",
  });
  return resp;
}

export async function getvncmetrics(filterValue) {
  const resp = await axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/getvncmetrics?filterValue=" + JSON.stringify(filterValue),
  });
  return resp;
}

export async function getfotametrics(filterValue) {
  const resp = await axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/getfotametrics?filterValue=" + JSON.stringify(filterValue),
  });
  return resp;
}

export async function getsyncmetrics(filterValue) {
  const resp = await axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/getsyncmetrics?filterValue=" + JSON.stringify(filterValue),
  });
  return resp;
}

export async function getmodelseriesmetrics(filterValue) {
  const resp = await axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/getmodelseriesmetrics?filterValue=" + JSON.stringify(filterValue),
  });
  return resp;
}

export async function getd2cmetrics(filterValue) {
  var url = ""
  if (filterValue === undefined) {
    url = process.env.REACT_APP_BACKENDURL + "/getd2cmetrics"
  }
  else {
    url = process.env.REACT_APP_BACKENDURL + "/getd2cmetrics?filterValue=" + JSON.stringify(filterValue)    
  }

  const resp = await axios({
    method: "GET",
    "url": url,
  });
  return resp;
}

export async function gettelemetrymetrics(filterValue) {
  var url = ""
  if (filterValue === undefined) {
    url = process.env.REACT_APP_BACKENDURL + "/gettelemetrymetrics"
  }
  else {
    url = process.env.REACT_APP_BACKENDURL + "/gettelemetrymetrics?filterValue=" + JSON.stringify(filterValue)    
  }

  const resp = await axios({
    method: "GET",
    "url": url,
  });
  return resp;
}

export async function getusermetrics() {
  const resp = await axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/getusermetrics",
  });
  return resp;
}

export async function getsitemismatch(filterValue) {
  var url = ""
  if (filterValue === undefined) {
    url = process.env.REACT_APP_BACKENDURL + "/getsitemismatch"
  }
  else {
    url = process.env.REACT_APP_BACKENDURL + "/getsitemismatch?filterValue=" + JSON.stringify(filterValue)    
  }
  
  const resp = await axios({
    method: "GET",
    "url": url,
  });
  return resp;
}

export async function getsite_mismatch_activity() {
  const resp = await axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/getsite_mismatch_activity_metrics",
  });
  return resp;
}

export async function getvehiclespaired() {
  const resp = await axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/getvehiclespaired",
  });
  return resp;
}

export async function getdistributiondetails(skip, limit, sortInfo, groupBy, filterValue) {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/distributiondetails?skip=" + skip + "&limit=" + limit + "&sortInfo=" + sortInfo + "&filterValue=" + JSON.stringify(filterValue),
  })
    .then((data) => {
      if (data) {
        return data.data
      }
      else {
        return { "data": [], "count": 0 }
      }
    });
  //return resp;
}

export async function getlov(skip, limit, sortInfo, groupBy, filterValue, type) {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/lov?skip=" + skip + "&limit=" + limit + "&sortInfo=" + sortInfo + "&filterValue=" + JSON.stringify(filterValue) + "&type=" + type,
  })
    .then((data) => {
      if (data) {
        return data.data
      }
      else {
        return { "data": [], "count": 0 }
      }
    });
  //return resp;
}

export async function getsiteswapslast24hrs() {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/getsiteswapslast24hrs",
  })
    .then((data) => {
      if (data) {
        return data
      }
      else {
        return []
      }
    });
  //return resp;
}

export async function getvehiclekeyhourdata(filterValue) {
  var url = ""
  if (filterValue === undefined) {
    url = process.env.REACT_APP_BACKENDURL + "/getvehiclekeyhourdata"
  }
  else {
    url = process.env.REACT_APP_BACKENDURL + "/getvehiclekeyhourdata?filterValue=" + JSON.stringify(filterValue)    
  }

  const resp = await axios({
    method: "GET",
    "url": url,
  });
  return resp;
}

export async function getSAPdbupdateddata() {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/getSAPdbupdateddata",
  })
    .then((data) => {
      if (data) {
        return data
      }
      else {
        return []
      }
    });
}

export async function getMDMdbupdateddata() {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/getMDMdbupdateddata",
  })
    .then((data) => {
      if (data) {
        return data
      }
      else {
        return []
      }
    });
}


export async function getinstallcompletelast24hrs() {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + "/getinstallcompletelast24hrs",
  })
    .then((data) => {
      if (data) {
        return data
      }
      else {
        return []
      }
    });
  //return resp;
}

export async function getrbac(username) {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + `/getrbac?username=${username}`,
  })
    .then((data) => {
      if (data) {
        return data
      }
      else {
        return []
      }
    })
    .catch((e)=>{
      return {}
    })
}


export async function getIWGFailureLogs() {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + `/getIWGFailureLogs`,
  })
    .then((data) => {
      if (data) {
        return data
      }
      else {
        return []
      }
    })
    .catch((e)=>{
      return {}
    })
}

export async function getIWGMetricsData() {
  return axios({
    method: "GET",
    url: process.env.REACT_APP_BACKENDURL + `/getiwgmetricsdata`,
  })
    .then((data) => {
      if (data) {
        return data
      }
      else {
        return []
      }
    })
    .catch((e)=>{
      return {}
    })
}