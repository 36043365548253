import React from "react";
import { useState, useEffect } from "react";
import { Row, Col, Card,  Badge } from 'react-bootstrap';
import "react-datetime/css/react-datetime.css";
import { dependencies, jobs } from "../service/getdata";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/index.css";
import "@inovua/reactdatagrid-community/theme/default-dark.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

function Dependencies() {
  const [dependenciesdata, setdependenciesdata] = useState([]);
  const [jobsdata, setjobsdata] = useState([]);

  const gridStyle = { minHeight: 500, fontSize: 10 }
  const rowStyle = ({ data }) => {
    // const colorMap = {
    //   ca: '#7986cb',
    //   uk: '#ef9a9a'
    // }

    return {
      fontSize: 12, // <-- or here
      //color: colorMap[data.country]
      // display: "inline-block",
      // "line-height": 1.5,
      // "overflow-wrap": "break-word",
      // "white-space": "normal",
      // "text-align": "justify",
    }
  }
  const filterValue =
    [    
      { name: 'rowKey', type: 'string', operator: 'contains', value: '' },
      { name: 'timestamp', type: 'string', operator: 'contains', value: '' },
      { name: 'AppRoleName', type: 'string', operator: 'contains', value: '' },
      { name: 'DependencyType', type: 'string', operator: 'contains', value: '' },
      { name: 'OperationName', type: 'string', operator: 'contains', value: '' },
      { name: 'count_', type: 'string', operator: 'contains', value: '' },
      { name: 'max_DurationMs', type: 'string', operator: 'contains', value: '' },
      { name: 'workspaceid', type: 'string', operator: 'contains', value: '' },
    ]

  const gridcols = [
    { header: "status", name: 'status', type: 'string', minWidth: 50, textAlign: 'center' },
    { header: "Name", name: 'rowKey', type: 'string', minWidth: 300, headerAlign: 'center' },
    { header: "Since", name: 'timestamp', type: 'datetime', minWidth: 100, headerAlign: 'center' },
    { header: "AppRoleName", name: 'AppRoleName', type: 'string', minWidth: 100, textAlign: 'center' },
    { header: "DependencyType", name: 'DependencyType', type: 'string', minWidth: 200, textAlign: 'center' },
    { header: "OperationName", name: 'OperationName', type: 'string', minWidth: 200, textAlign: 'center' },
    { header: "count_", name: 'count_', type: 'string', minWidth: 100, textAlign: 'center' },
    { header: "max_DurationMs", name: 'max_DurationMs', type: 'string', minWidth: 100, textAlign: 'center' },
    { header: "workspaceid", name: 'workspaceid', type: 'string', minWidth: 100, textAlign: 'center' },    
  ]

  const defaultSortInfo = { name: 'timestamp', dir: -1, type: 'datetime' }

  const findlatesttimestampjob = (datum) => {
    let dates = jobsdata.filter((a) => a.rowKey.indexOf(datum) >= 0).map((b) => new Date(b.timestamp))
    return new Date(Math.max.apply(null, dates)).toLocaleString();
  }

  useEffect(() => {
    jobs().then((d) => {
      //console.log(db)
      setjobsdata(JSON.parse(JSON.stringify(d?.data)))

    });
  }, [jobsdata.length])

  useEffect(() => {
    dependencies().then((d) => {
      var datum = JSON.parse(JSON.stringify(d?.data))
      datum.map((item) => {
        item.timestamp = new Date(item.timestamp).toLocaleString()
        if (item.count_ < 10)
          item["status"] = <FontAwesomeIcon icon={faCircle} className='text-success' />
        else
          item["status"] = <FontAwesomeIcon icon={faCircle} className='text-danger' />
      })
      setdependenciesdata(datum)
    });
  }, [dependenciesdata.length])

  return (
    <>
      <Row>
        <Col className="m-1">
          <Card>
            <Card.Header >
              <strong>Dependencies</strong>
              {
                ((new Date().getTime() - new Date(findlatesttimestampjob("dependencies")).getTime()) > 300000)
                  ?
                  <Badge pill style={{ float: 'right' }} bg="warning">
                    {
                      findlatesttimestampjob("dependencies")
                    }
                  </Badge>
                  :
                  <Badge pill style={{ float: 'right' }} bg="success">
                    {
                      findlatesttimestampjob("dependencies")
                    }
                  </Badge>
              }
            </Card.Header>
            <Card.Body>
              {/* <Table className='table-sm table-striped od-font-sm od-table'>
                <thead>
                  <tr>
                    <th className='small'>Name</th>
                    <th className='small'>Since</th>
                    <th className='small'>AppRoleName</th>
                    <th className='small'>DependencyType</th>
                    <th className='small'>OperationName</th>
                    <th className='small'>count_</th>
                    <th className='small'>max_DurationMs</th>
                    <th className='small'>workspaceid</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    dependenciesdata.map((item, id) => {
                      return <tr key={id}>
                        <td className='small'>{item.rowKey}</td>
                        <td className='small'>{new Date(item.timestamp).toLocaleString()}</td>
                        <td className='small'>{item.AppRoleName}</td>
                        <td className='small'>{item.DependencyType}</td>
                        <td className='small'>{item.OperationName}</td>
                        <td className='small'>{item.count_}</td>
                        <td className='small'>{item.max_DurationMs}</td>
                        <td className='small'>{item.workspaceid}</td>
                      </tr>
                    })
                  }
                </tbody>
              </Table> */}
              <ReactDataGrid
                defaultSortInfo={defaultSortInfo}
                //idProperty="_id"
                //className="mb-4"
                style={gridStyle}
                rowStyle={rowStyle}
                columns={gridcols}
                //selected={props.selected}
                dataSource={dependenciesdata}
                //onRowClick={handleGridRowClick}
                defaultFilterValue={filterValue}
                enableColumnAutosize
                pagination="local"
                //checkboxColumn={props.checkboxreq}
                //multiSelect={props.multiSelect}
                //editable={props.editable}
                //onSelectionChange={(e) => onSelectionChange(e)}
                defaultLimit={10}
                //defaultSkip={0}
                pageSizes={[25, 50, 100, 500]}
              //maxWidth ={600}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Dependencies;
