import React from "react";
import { useState, useEffect } from "react";
import { Row, Col, Card, Table,  Badge } from 'react-bootstrap';
import "react-datetime/css/react-datetime.css";
import { apimetrics,jobs} from "../service/getdata";

function Api() {
  const [api, setapi] = useState([]);
  const [jobsdata, setjobsdata] = useState([]);
  const findlatesttimestampjob = (datum) => {
    let dates = jobsdata.filter((a)=>a.rowKey.indexOf(datum)>=0).map((b)=>new Date(b.timestamp))
    return new Date(Math.max.apply(null, dates)).toLocaleString();    
  }

  useEffect(() => {
    jobs().then((d) => {
      //console.log(db)
      setjobsdata(JSON.parse(JSON.stringify(d?.data)))

    });
  }, [jobsdata.length])

  useEffect(() => {
    apimetrics().then((d) => {
      //console.log(db)
      setapi(JSON.parse(JSON.stringify(d?.data)))
    });
  }, [api.length])

 


  return (
    <>     
      <Row>
        <Col className="m-1">
          <Card style={{ height: 500 }}>
            <Card.Header >
              <strong>API Details</strong>
              {                
                ((new Date().getTime() - new Date(findlatesttimestampjob("api")).getTime()) > 300000)
                ?
                <Badge pill style={{ float: 'right' }} bg="warning">
                  {
                    findlatesttimestampjob("api")
                  }
                </Badge>
                :
                <Badge pill style={{ float: 'right' }} bg="success">
                  {
                    findlatesttimestampjob("api")
                  }
                </Badge>
              }
            </Card.Header>
            <Card.Body style={{ overflow: 'auto' }}>              
              <Table className='table-sm table-striped od-font-sm'>
                <thead>
                  <tr>
                    <th className='small'>Api</th>
                    {/* <th className='small'>TimeStamp</th> */}
                    <th className='small'>Avg</th>
                    <th className='small'>Min</th>
                    <th className='small'>Max</th>
                    <th className='small'>CountFailed</th>
                    <th className='small'>CountSuccess</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    api.map((item, id) => {
                      return <tr key={id}>
                        <td className='small'>{item.rowKey}</td>
                        {/* <td className='small'>{new Date(item.timestamp).toLocaleString()}</td> */}
                        <td className='small'>{parseFloat(item.apiTimeAvg).toFixed(2)}</td>
                        <td className='small'>{parseFloat(item.apiTimeMin).toFixed(2)}</td>
                        <td className='small'>{parseFloat(item.apiTimeMax).toFixed(2)}</td>
                        <td className='small'>{item.callCountFailed}</td>
                        <td className='small'>{item.callCountSuccess}</td>
                      </tr>
                    })
                  }
                </tbody>
              </Table>
              
            </Card.Body>
          </Card>
        </Col>
        

      </Row>
    </>
  );
}

export default Api;
