import React from "react";
import { useState, useEffect } from "react";
import { Row, Col, Card, Table, Badge, Accordion } from 'react-bootstrap';
import "react-datetime/css/react-datetime.css";
import { jobs, thresholds } from "../service/getdata";

function Jobs() {
  const [jobsdata, setjobsdata] = useState([]);
  const [thresholddata, setthresholddata] = useState([]);

  const findlatesttimestamp = (datum) => {
    let dates = datum.map((a) => { return new Date(a.timestamp) })
    return new Date(Math.max.apply(null, dates)).toLocaleString();
  }

  useEffect(() => {
    jobs().then((d) => {
      setjobsdata(JSON.parse(JSON.stringify(d?.data)))
    });
  }, [jobsdata.length])

  useEffect(() => {
    thresholds().then((d) => {
      setthresholddata(JSON.parse(JSON.stringify(d?.data)))
    });
  }, [thresholddata.length])

  return (
    <>
      <Row>
        <Col className="m-1">
          <Card>
            <Card.Header >
              <strong>Jobs</strong>
              {
                ((new Date().getTime() - new Date(findlatesttimestamp(jobsdata)).getTime()) > 300000)
                  ?
                  <Badge pill style={{ float: 'right' }} bg="warning">
                    {
                      findlatesttimestamp(jobsdata)
                    }
                  </Badge>
                  :
                  <Badge pill style={{ float: 'right' }} bg="success">
                    {
                      findlatesttimestamp(jobsdata)
                    }
                  </Badge>
              }
            </Card.Header>
            <Card.Body>
              <Table className='table-sm table-striped od-font-sm od-table'>
                <thead>
                  <tr>
                    <th className='small'>Job</th>
                    <th className='small'>Created</th>
                    <th className='small'>Deleted</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    jobsdata.map((item, id) => {
                      return <tr key={id}>
                        <td className='small'>{item.rowKey}</td>
                        <td className='small'>{new Date(item.created).toLocaleString()}</td>
                        <td className='small'>{new Date(item.deleted).toLocaleString()}</td>
                      </tr>
                    })
                  }
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className="m-1">
          <Card>
            <Card.Header >
              <strong>Thresholds</strong>
            </Card.Header>
            <Card.Body>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>IOT</Accordion.Header>
                  <Accordion.Body>
                    <Table className='table-sm table-striped od-font-sm od-table'>
                      <thead>
                        <tr>
                          <th className='small'>Area</th>
                          <th className='small'>AverageLatency</th>
                          <th className='small'>C2DMessagesExpired</th>
                          <th className='small'>C2DMessagesRejected</th>
                          <th className='small'>C2DMessagesAbandoned</th>
                          <th className='small'>C2DMessagesSent</th>
                          <th className='small'>D2CMessagesDropped</th>
                          <th className='small'>D2CMessagesReceived</th>
                          <th className='small'>DeviceCount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          thresholddata.map((item, id) => {
                            return <tr key={id}>
                              <td className='small'>{item.rowKey}</td>
                              <td className='small'>{item.AverageLatency}</td>
                              <td className='small'>{item.C2DMessagesAbandoned}</td>
                              <td className='small'>{item.C2DMessagesExpired}</td>
                              <td className='small'>{item.C2DMessagesRejected}</td>
                              <td className='small'>{item.C2DMessagesSent}</td>
                              <td className='small'>{item.D2CMessagesDropped}</td>
                              <td className='small'>{item.D2CMessagesReceived}</td>
                              <td className='small'>{item.DeviceCount}</td>
                            </tr>
                          })
                        }
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Stream Analytics</Accordion.Header>
                  <Accordion.Body>

                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Jobs;
