import React from "react";
import { useState, useEffect } from "react";
import { Row, Col, Card, Table, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import "react-datetime/css/react-datetime.css";
import { dbmetrics, cosmosdbmetrics, jobs } from "../service/getdata";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/index.css";
import "@inovua/reactdatagrid-community/theme/default-dark.css";


function Database() {

  const [jobsdata, setjobsdata] = useState([]);
  const [db, setdb] = useState([]);
  const [cosmosdb, setcosmosdb] = useState([]);
  const gridStyle = { minHeight: 290, fontSize: 10 }
  const rowStyle = ({ data }) => {
    // const colorMap = {
    //   ca: '#7986cb',
    //   uk: '#ef9a9a'
    // }

    return {
      fontSize: 12, // <-- or here
      //color: colorMap[data.country]
      // display: "inline-block",
      // "line-height": 1.5,
      // "overflow-wrap": "break-word",
      // "white-space": "normal",
      // "text-align": "justify",
    }
  }
  const filterValue =
    [
      { name: 'dbname', type: 'string', operator: 'contains', value: '' },
      { name: 'rowKey', type: 'string', operator: 'contains', value: '' }

    ]

  const gridcols = [
    { header: "Database", name: 'dbname', type: 'string', minWidth: 300, headerAlign: 'center' },
    { header: "Container", name: 'rowKey', type: 'string', minWidth: 100, headerAlign: 'center' },
    { header: "Total RU's", name: 'totalru', type: 'number', minWidth: 100, textAlign: 'center' },
    { header: "Requests-Success", name: 'TotalRequests_success', type: 'number', minWidth: 200, textAlign: 'center' },
    { header: "Requests-Error", name: 'TotalRequests_clientothererror', type: 'number', minWidth: 200, textAlign: 'center' },
    { header: "Requests-Throttling", name: 'TotalRequests_clientthrottlingerror', type: 'number', minWidth: 200, textAlign: 'center' },
    { header: "Status", name: 'status', type: 'string', minWidth: 100, textAlign: 'center' },
  ]

  const defaultSortInfo = { name: 'dbname', dir: 1, type: 'string' }

  const findlatesttimestampjob = (datum) => {
    let dates = jobsdata.filter((a) => a.rowKey.indexOf(datum) >= 0).map((b) => new Date(b.timestamp))
    return new Date(Math.max.apply(null, dates)).toLocaleString();
  }

  useEffect(() => {
    jobs().then((d) => {
      //console.log(db)
      setjobsdata(JSON.parse(JSON.stringify(d?.data)))

    });
  }, [jobsdata.length])


  useEffect(() => {
    dbmetrics().then((d) => {
      //console.log(db)
      setdb(JSON.parse(JSON.stringify(d?.data)))
    });
  }, [db.length])


  useEffect(() => {
    cosmosdbmetrics().then((d) => {
      var datum = JSON.parse(JSON.stringify(d?.data))
      datum.map((item) => {
        item["totalru"] = parseFloat(
          parseFloat(
            item.TotalRequestUnits_clientthrottlingerror ?
              parseFloat(item.TotalRequestUnits_clientthrottlingerror).toFixed(2)
              :
              0
          )
          +
          parseFloat(
            item.TotalRequestUnits_clientothererror ?
              parseFloat(item.TotalRequestUnits_clientothererror).toFixed(2)
              :
              0
          )
          +
          parseFloat(
            item.TotalRequestUnits_success ?
              parseFloat(item.TotalRequestUnits_success).toFixed(2)
              :
              0
          )
        ).toFixed(2)
        let throttled = item.TotalRequests_clientthrottlingerror ? item.TotalRequests_clientthrottlingerror : 0;
        let success = item.TotalRequests_clientsuccess ? item.TotalRequests_clientsuccess : 0;
          
        if (throttled === 0) {
          item["status"] = <FontAwesomeIcon icon={faCircle} className='text-success' />
        }
        else if (success === 0) {
          item["status"] = <FontAwesomeIcon icon={faCircle} className='text-secondary' />
        }
        else {
          if (parseFloat(parseFloat(throttled) / parseFloat(success)) > 0.04)
            item["status"] = <FontAwesomeIcon icon={faCircle} className='text-warn' />
          else
            item["status"] = <FontAwesomeIcon icon={faCircle} className='text-success' />
        }
      })
      //console.log(db)
      setcosmosdb(datum)
    });
  }, [cosmosdb.length])

  return (
    <>
      <Row>
        <Col className="m-1">
          <Card>
            <Card.Header >
              <strong>SQL Database</strong>
              {
                ((new Date().getTime() - new Date(findlatesttimestampjob("db")).getTime()) > 300000)
                  ?
                  <Badge pill style={{ float: 'right' }} bg="warning">
                    {
                      findlatesttimestampjob("db")
                    }
                  </Badge>
                  :
                  <Badge pill style={{ float: 'right' }} bg="success">
                    {
                      findlatesttimestampjob("db")
                    }
                  </Badge>
              }
            </Card.Header>
            <Card.Body>
              <Table className='table-sm table-striped od-font-sm od-table'>
                <thead>
                  <tr>
                    <th className='small'>Database</th>
                    <th className='small'>Avg</th>
                    <th className='small'>Min</th>
                    <th className='small'>Max</th>
                    <th className='small'>deadlock</th>
                    <th className='small'>sessions_count</th>
                    <th className='small'>storage_percent</th>
                    <th className='small'>tempdb_log_used_percent</th>
                    <th className='small'>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    db.map((item, id) => {
                      return <tr key={id}>
                        <td className='small'>{item.rowKey}</td>
                        <td className='small'>{item.avg}</td>
                        <td className='small'>{item.min}</td>
                        <td className='small'>{item.max}</td>
                        <td className='small'>{item.deadlock}</td>
                        <td className='small'>{item.sessions_count}</td>
                        <td className='small'>{item.storage_percent}</td>
                        <td className='small'>{item.tempdb_log_used_percent}</td>
                        <td className='small'>
                          <FontAwesomeIcon icon={faCircle}
                            className=
                            {
                              item.storage_percent > 80 || item.avg > 65 || item.max > 80
                                ?
                                'text-danger'
                                :
                                'text-success'
                            }
                          /></td>
                      </tr>
                    })
                  }
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className="m-1">
          <Card style={{ height: '350px' }}>
            <Card.Header >
              <strong>COSMOS Database</strong>
              {
                ((new Date().getTime() - new Date(findlatesttimestampjob("db")).getTime()) > 300000)
                  ?
                  <Badge pill style={{ float: 'right' }} bg="warning">
                    {
                      findlatesttimestampjob("db")
                    }
                  </Badge>
                  :
                  <Badge pill style={{ float: 'right' }} bg="success">
                    {
                      findlatesttimestampjob("db")
                    }
                  </Badge>
              }
            </Card.Header>
            <Card.Body style={{ overflow: 'auto' }} className="m-0 p-0">
              {/* <Table className='table-sm table-striped od-font-sm od-table'>
                <thead>
                  <tr>
                    <th className='small'>Database</th>
                    <th className='small'>Container</th>
                    <th className='small'>Total RU's</th>
                    <th className='small'>Successful Requests</th>
                    <th className='small'>Failed Requests</th>
                    <th className='small'>Throttled Requests</th>                    
                  </tr>
                </thead>
                <tbody>
                  {
                    cosmosdb.map((item, id) => {
                      return <tr key={id}>
                        <td className='small'>{item.dbname}</td>
                        <td className='small'>{item.rowKey}</td>
                        <td className='small'>
                          {
                            parseFloat(
                              parseFloat(
                                item.TotalRequestUnits_ClientThrottlingError ?
                                  parseFloat(item.TotalRequestUnits_ClientThrottlingError).toFixed(2)
                                  :
                                  0
                              )
                              +
                              parseFloat(
                                item.TotalRequestUnits_ClientOtherError ?
                                  parseFloat(item.TotalRequestUnits_ClientOtherError).toFixed(2)
                                  :
                                  0
                              )
                              +
                              parseFloat(
                                item.TotalRequestUnits_Success ?
                                  parseFloat(item.TotalRequestUnits_Success).toFixed(2)
                                  :
                                  0
                              )
                            ).toFixed(2)
                          }
                        </td>
                        <td className='small'>{item.TotalRequests_Success ? item.TotalRequests_Success : 0}</td>
                        <td className='small'>{item.TotalRequests_ClientOtherError ? item.TotalRequests_ClientOtherError : 0}</td>
                        <td className='small'>{item.TotalRequests_ClientThrottlingError ? item.TotalRequests_ClientThrottlingError : 0}</td>                        
                      </tr>
                    })
                  }
                </tbody>
              </Table> */}

              <ReactDataGrid
                defaultSortInfo={defaultSortInfo}
                //idProperty="_id"
                //className="mb-4"
                style={gridStyle}
                rowStyle={rowStyle}
                columns={gridcols}
                //selected={props.selected}
                dataSource={cosmosdb}
                //onRowClick={handleGridRowClick}
                defaultFilterValue={filterValue}
                enableColumnAutosize
                pagination="local"
                //checkboxColumn={props.checkboxreq}
                //multiSelect={props.multiSelect}
                //editable={props.editable}
                //onSelectionChange={(e) => onSelectionChange(e)}
                defaultLimit={4}
                //defaultSkip={0}
                pageSizes={[25, 50, 100, 500]}
              //maxWidth ={600}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Database;
