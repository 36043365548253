import React from "react";
import { useState, useEffect } from "react";
import { Row, Col, Card, Table, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import "react-datetime/css/react-datetime.css";
import { portalmetrics, releasemetrics, jobs} from "../service/getdata";


function Dashboard(props) {
  const [portal, setportal] = useState([]);
  const [release, setrelease] = useState([]);
  const [jobsdata, setjobsdata] = useState([]);
  

  const findlatesttimestampjob = (datum) => {
    let dates = jobsdata.filter((a) => a.rowKey.indexOf(datum) >= 0).map((b) => new Date(b.timestamp))
    return new Date(Math.max.apply(null, dates)).toLocaleString();
  }

  

  useEffect(() => {
    jobs().then((d) => {
      //console.log(db)
      setjobsdata(JSON.parse(JSON.stringify(d?.data)))

    });
  }, [jobsdata.length])

  useEffect(() => {
    portalmetrics().then((d) => {
      //console.log(iot)
      setportal(d?.data)

    });
  }, [portal.length])

  useEffect(() => {
    releasemetrics().then((d) => {
      //console.log(iot)
      setrelease(d?.data)
    });
  }, [release.length])

  

  return (
    <>
      <Row>
        <Col className="m-1">
          <Card style={{ height: 375 }}>
            <Card.Header >
              <strong>Portal Health</strong>
              {
                ((new Date().getTime() - new Date(findlatesttimestampjob("portal")).getTime()) > 300000)
                  ?
                  <Badge pill style={{ float: 'right' }} bg="warning">
                    {
                      findlatesttimestampjob("portal")
                    }
                  </Badge>
                  :
                  <Badge pill style={{ float: 'right' }} bg="success">
                    {
                      findlatesttimestampjob("portal")
                    }
                  </Badge>
              }
            </Card.Header>
            <Card.Body>
              <Table className='table-sm table-striped od-font-sm'>
                <thead>
                  <tr>
                    <th className='small'>Portal</th>
                    <th className='small'>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    portal.map((item, id) => {
                      return <tr key={id}>
                        <td className='small'>{item.rowKey}</td>
                        <td className='small'>
                          <FontAwesomeIcon icon={faCircle} className={item.status ? 'text-success' : 'text-danger'} />
                        </td>
                      </tr>
                    })
                  }
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col className="m-1">
          <Card style={{ height: 375 }} >
            <Card.Header>
              <strong>Build & Release</strong>
              {
                ((new Date().getTime() - new Date(findlatesttimestampjob("release")).getTime()) > 300000)
                  ?
                  <Badge pill style={{ float: 'right' }} bg="warning">
                    {
                      findlatesttimestampjob("release")
                    }
                  </Badge>
                  :
                  <Badge pill style={{ float: 'right' }} bg="success">
                    {
                      findlatesttimestampjob("release")
                    }
                  </Badge>
              }
            </Card.Header>
            <Card.Body>
              <Table className='table-sm table-striped od-font-sm'>
                <thead>
                  <tr>
                    <th className='small'>Area</th>
                    <th className='small'>Branch</th>
                    <th className='small'>Version</th>
                    <th className='small'>Date &#40;Local Time&#41;</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    release.map((item, id) => {
                      return <tr key={id}>
                        <td className='small'>{item.rowKey}</td>
                        <td className='small'>{item.branch}</td>
                        <td className='small'>{item.version}</td>
                        <td className='small'>{new Date(item.date).toLocaleString()}</td>
                      </tr>
                    })
                  }
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Dashboard;
