import React from "react";
import { useState, useEffect } from "react";
import { Container,  Row, Col, Card, Badge } from 'react-bootstrap';
import "react-datetime/css/react-datetime.css";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/index.css";
import "@inovua/reactdatagrid-community/theme/default-dark.css";
import { alerts,jobs } from "../service/getdata"

function Alerts() {
  const [alertsdata, setalertsdata] = useState([]);
  const [jobsdata, setjobsdata] = useState([]);
  const findlatesttimestampjob = (datum) => {
    let dates = jobsdata.filter((a)=>a.rowKey.indexOf(datum)>=0).map((b)=>new Date(b.timestamp))
    return new Date(Math.max.apply(null, dates)).toLocaleString();    
  }

  useEffect(() => {
    jobs().then((d) => {
      //console.log(db)
      setjobsdata(JSON.parse(JSON.stringify(d?.data)))

    });
  }, [jobsdata.length])

  
  const gridStyle = { minHeight: 475, marginTop: 10, fontSize: 5 }
  const rowStyle = ({ data }) => {
    // const colorMap = {
    //   ca: '#7986cb',
    //   uk: '#ef9a9a'
    // }

    return {
      fontSize: 12, // <-- or here
      //color: colorMap[data.country]
      // display: "inline-block",
      // "line-height": 1.5,
      // "overflow-wrap": "break-word",
      // "white-space": "normal",
      // "text-align": "justify",
    }
  }
  const filterValue =
    [
      { header: "Resource Group", name: 'targetResourceGroup', type: 'string', minWidth: 100, operator: 'startsWith', value: '' },
      { header: "Resource", name: 'targetResourceName', type: 'string', minWidth: 100, operator: 'startsWith', value: '' },
      { header: "Alert Id", name: 'id', type: 'string', minWidth: 300, operator: 'startsWith', value: '' },
      { header: "Fire Time (UTC)", name: 'startDateTime', type: 'string', minWidth: 250, operator: 'startsWith', value: '' },
      { header: "Severity", name: 'severity', type: 'string', minWidth: 100, operator: 'startsWith', value: '' },
      { header: "Alert Name", name: 'name', type: 'string', minWidth: 300, operator: 'startsWith', value: '' }
    ]

  const gridcols = [
    { header: "Resource Group", name: 'targetResourceGroup', type: 'string', minWidth: 100 },
    { header: "Resource", name: 'targetResourceName', type: 'string', minWidth: 100 },
    { header: "Alert Id", name: 'id', type: 'string', minWidth: 300 },
    { header: "Fire Time (UTC)", name: 'startDateTime', type: 'string', minWidth: 250 },
    { header: "Severity", name: 'severity', type: 'string', minWidth: 100 },
    { header: "Alert Name", name: 'name', type: 'string', minWidth: 300 },
    { header: "Status", name: 'monitorCondition', type: 'string', minWidth: 100 },
  ]
  const defaultSortInfo = { name: 'startDateTime', dir: -1, type: 'datetime' }

  useEffect(() => {
    alerts().then((d) => {
      var datum = JSON.parse(JSON.stringify(d?.data))
      datum.map((item) => {
        item.startDateTime = new Date(item.startDateTime).toLocaleString()
      })
      setalertsdata(datum)
    });
  }, [alertsdata.length])

  return (
    <>
      <Container fluid className="m-1">
        <Row>
          <Col className="m-1">
            <Card>
              <Card.Header >
                <strong>Alerts</strong>
                {                
                ((new Date().getTime() - new Date(findlatesttimestampjob("alerts")).getTime()) > 300000)
                ?
                <Badge pill style={{ float: 'right' }} bg="warning">
                  {
                    findlatesttimestampjob("alerts")
                  }
                </Badge>
                :
                <Badge pill style={{ float: 'right' }} bg="success">
                  {
                    findlatesttimestampjob("alerts")
                  }
                </Badge>
              }
              </Card.Header>
              <Card.Body>
                <ReactDataGrid
                  //idProperty="_id"
                  //className="mb-4"
                  defaultSortInfo={defaultSortInfo}
                  style={gridStyle}
                  rowStyle={rowStyle}
                  columns={gridcols}
                  //selected={props.selected}
                  dataSource={alertsdata}
                  //onRowClick={handleGridRowClick}
                  defaultFilterValue={filterValue}
                  enableColumnAutosize
                  pagination="local"
                  //checkboxColumn={props.checkboxreq}
                  //multiSelect={props.multiSelect}
                  //editable={props.editable}
                  //onSelectionChange={(e) => onSelectionChange(e)}
                  defaultLimit={10}
                  //defaultSkip={0}
                  pageSizes={[25, 50, 100, 500]}
                //maxWidth ={600}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>

      </Container>
    </>
  );
}

export default Alerts;
